// @flow
import {
    MYETH_WITHDRAWALS,
    MYETH_WITHDRAWALS_SUCCESS,
    MYETH_WITHDRAWALS_FAILED,
} from '../../constants/actionTypes';

type RequestAction = { type: string, payload: {} | string };

export const myethWithdrawals = (): RequestAction => ({
    type: MYETH_WITHDRAWALS,
    payload: { }
});

export const myethWithdrawalsSuccess = (myethWithdrawalsResponse: any): RequestAction => ({
    type: MYETH_WITHDRAWALS_SUCCESS,
    payload: myethWithdrawalsResponse
});

export const myethWithdrawalsFailed = (error: string): RequestAction => ({
    type: MYETH_WITHDRAWALS_FAILED,
    payload: error
});

