// @flow
import {
    USER_ASSETS,
    USER_ASSETS_SUCCESS,
    USER_ASSETS_FAILED,

    USER_INFO,
    USER_INFO_SUCCESS,
    USER_INFO_FAILED,

    USER_EDIT,

    USER_WALLET_LOG,
    USER_WALLET_LOG_SUCCESS,
    USER_WALLET_LOG_FAILED,
} from '../../constants/actionTypes';

//import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    //user: getLoggedInUser(),
    loading: false
};

type UserAction = { type: string, payload: {} | string };
//type State = { user?: {} | null, loading?: boolean, +value?: boolean };
type State = { loading?: boolean, +value?: boolean };

const Management = (state:State = INIT_STATE, action: UserAction) => {
    switch (action.type) {
        case USER_ASSETS:
            return { ...state, loading: true };
        case USER_ASSETS_SUCCESS:
            return { ...state, userAssetsResponse: action.payload, loading: false, error: null };
        case USER_ASSETS_FAILED:
            return { ...state, error: action.payload, loading: false };
        case USER_INFO:
            return { ...state, loading: true };
        case USER_INFO_SUCCESS:
            return { ...state, userInfoResponse: action.payload, loading: false, error: null };
        case USER_INFO_FAILED:
            return { ...state, error: action.payload, loading: false };
        case USER_EDIT:
            return { ...state, loading: false };
        case USER_WALLET_LOG:
            return { ...state, loading: true };
        case USER_WALLET_LOG_SUCCESS:
            return { ...state, userWalletLogResponse: action.payload, loading: false, error: null };
        case USER_WALLET_LOG_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default Management;

