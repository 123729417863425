// @flow
import {
    PAYABLE_LIST,
    PAYABLE_LIST_SUCCESS,
    PAYABLE_LIST_FAILED,
    PAYABLE_COLLECT,

    RECEIVABLE_LIST,
    RECEIVABLE_LIST_SUCCESS,
    RECEIVABLE_LIST_FAILED,
    RECEIVABLE_COLLECT,
} from '../../constants/actionTypes';

//import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    //user: getLoggedInUser(),
    loading: false
};

type UserAction = { type: string, payload: {} | string };
//type State = { user?: {} | null, loading?: boolean, +value?: boolean };
type State = { loading?: boolean, +value?: boolean };

const Transfer = (state:State = INIT_STATE, action: UserAction) => {
    switch (action.type) {
        case PAYABLE_LIST:
            return { ...state, loading: true };
        case PAYABLE_LIST_SUCCESS:
            return { ...state, payableListResponse: action.payload, loading: false, error: null };
        case PAYABLE_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PAYABLE_COLLECT:
            return { ...state, loading: true };

        case RECEIVABLE_LIST:
            return { ...state, loading: true };
        case RECEIVABLE_LIST_SUCCESS:
            return { ...state, receivableListResponse: action.payload, loading: false, error: null };
        case RECEIVABLE_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case RECEIVABLE_COLLECT:
            return { ...state, loading: true };
        default: return { ...state };
    }
}

export default Transfer;

