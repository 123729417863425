// @flow
import {
    PROFIT_INFO,
    PROFIT_INFO_SUCCESS,
    PROFIT_INFO_FAILED,
    PROFIT_CALCULATE,
    PROFIT_CALCULATE_SUCCESS,
    PROFIT_CALCULATE_FAILED,
    PROFIT_POST,
    PROFIT_POST_SUCCESS,
    PROFIT_POST_FAILED,
    PROFIT_CLEAR_ERROR,
} from '../../constants/actionTypes';

type PostAction = { type: string, payload: {} | string };

export const profitInfo = (year: int, month: int): PostAction => ({
    type: PROFIT_INFO,
    payload: { year, month }
});

export const profitInfoSuccess = (profitInfoResponse: any): PostAction => ({
    type: PROFIT_INFO_SUCCESS,
    payload: profitInfoResponse
});

export const profitInfoFailed = (error: string): PostAction => ({
    type: PROFIT_INFO_FAILED,
    payload: error
});

export const profitCalculate = (year: int, month: int, total_profit: string): PostAction => ({
    type: PROFIT_CALCULATE,
    payload: { year, month, total_profit }
});

export const profitCalculateSuccess = (profitCalculateResponse: any): PostAction => ({
    type: PROFIT_CALCULATE_SUCCESS,
    payload: profitCalculateResponse
});

export const profitCalculateFailed = (error: string): PostAction => ({
    type: PROFIT_CALCULATE_FAILED,
    payload: error
});

export const profitPost = (year: int, month: int): PostAction => ({
    type: PROFIT_POST,
    payload: { year, month }
});

export const profitPostSuccess = (profitPostResponse: any): PostAction => ({
    type: PROFIT_POST_SUCCESS,
    payload: profitPostResponse
});

export const profitPostFailed = (error: string): PostAction => ({
    type: PROFIT_POST_FAILED,
    payload: error
});

export const profitClearError = (): PostAction => ({
    type: PROFIT_CLEAR_ERROR,
    payload: {}
});

