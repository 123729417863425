// @flow

const INIT_STATE = { count: 0, logMsg : "Empty Message" };

type LogAction = { type: string, payload: {} | string };
type State = {count: Number, logMsg: {} | null};

const Log = (state:State = INIT_STATE, action: LogAction) => {
    if (process.env.NODE_ENV !== 'production') {
        console.log("Action:", action);
    }
    switch (action.type) {
        case 'LOG_ADD':
            return { ...state};
        default: 
            return { ...state};
    }
}

export default Log;
