//export const API_ROOT = 'https://wrex-dashboard-api.palrata.com'
//export const API_ROOT = 'https://w-rex.com/'
//export const API_ROOT = 'https://dashboard.staging.w-rex.com'
export const API_ROOT = 'https://api.op.sys.w-rex.com'

export const FAKE_API = false
export const API_TIMEOUT_TIME = 5000

export const MAX_DECIMAL_NUMBER=8;

export const DASH_VERSION = "v1.0";

