// @flow
import {
    PAYABLE_LIST,
    PAYABLE_LIST_SUCCESS,
    PAYABLE_LIST_FAILED,
    PAYABLE_COLLECT,

    RECEIVABLE_LIST,
    RECEIVABLE_LIST_SUCCESS,
    RECEIVABLE_LIST_FAILED,
    RECEIVABLE_COLLECT,
} from '../../constants/actionTypes';

type TransferAction = { type: string, payload: {} | string };

export const payableList = (account: string): TransferAction => ({
    type: PAYABLE_LIST,
    payload: { account }
});

export const payableListSuccess = (payableListResponse: any): TransferAction => ({
    type: PAYABLE_LIST_SUCCESS,
    payload: payableListResponse
});

export const payableListFailed = (error: string): TransferAction => ({
    type: PAYABLE_LIST_FAILED,
    payload: error
});

export const payableCollect = (transactions:any, response_receive:any): TransferAction => ({
    type: PAYABLE_COLLECT,
    payload: { transactions, response_receive }
});

export const receivableList = (account: string): TransferAction => ({
    type: RECEIVABLE_LIST,
    payload: { account }
});

export const receivableListSuccess = (receivableListResponse: any): TransferAction => ({
    type: RECEIVABLE_LIST_SUCCESS,
    payload: receivableListResponse
});

export const receivableListFailed = (error: string): TransferAction => ({
    type: RECEIVABLE_LIST_FAILED,
    payload: error
});

export const receivableCollect = (transactions:any, response_receive:any): TransferAction => ({
    type: RECEIVABLE_COLLECT,
    payload: { transactions, response_receive }
});

