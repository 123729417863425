// @flow
import {
    RESET_PASSWORD,

    LOGIN_HISTORY,
    LOGIN_HISTORY_SUCCESS,
    LOGIN_HISTORY_FAILED,

} from '../../constants/actionTypes';

//import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    //user: getLoggedInUser(),
    loading: false
};

type UserAction = { type: string, payload: {} | string };
//type State = { user?: {} | null, loading?: boolean, +value?: boolean };
type State = { loading?: boolean, +value?: boolean };

const User = (state:State = INIT_STATE, action: UserAction) => {
    switch (action.type) {
        case RESET_PASSWORD:
            return { ...state, loading: true };
        case LOGIN_HISTORY:
            return { ...state, loading: true };
        case LOGIN_HISTORY_SUCCESS:
            return { ...state, loginHistoryResponse: action.payload, loading: false, error: null };
        case LOGIN_HISTORY_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default User;

