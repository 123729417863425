// @flow
import {
    TRANSFER_FORM_LIST,
    TRANSFER_FORM_LIST_SUCCESS,
    TRANSFER_FORM_LIST_FAILED,

    TRANSFER_FORM_CANCEL,

    TRANSFER_FORM_CHECK,
    TRANSFER_FORM_CHECK_SUCCESS,
    TRANSFER_FORM_CHECK_FAILED,

    TRANSFER_FORM_SETTLE,
    TRANSFER_FORM_SETTLE_RESPONSE,
} from '../../constants/actionTypes';


const INIT_STATE = {
    loading: false
};

type PostAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean };

const Post = (state:State = INIT_STATE, action: PostAction) => {
    switch (action.type) {
        case TRANSFER_FORM_LIST:
            return { ...state, loading: true };
        case TRANSFER_FORM_LIST_SUCCESS:
            return { ...state, transferFormListResponse: action.payload, loading: false, error: null };
        case TRANSFER_FORM_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case TRANSFER_FORM_CANCEL:
            return { ...state, loading: true };
        case TRANSFER_FORM_CHECK:
            return { ...state, loading: true };
        case TRANSFER_FORM_CHECK_SUCCESS:
            return { ...state, transferFormCheckResponse: action.payload, loading: false, error: null };
        case TRANSFER_FORM_CHECK_FAILED:
            return { ...state, error: action.payload, loading: false };
        case TRANSFER_FORM_SETTLE:
            return { ...state, loading: true };
        case TRANSFER_FORM_SETTLE_RESPONSE:
            return { ...state, loading: false };
        default: return { ...state };
    }
}

export default Post;

