
export function Translations_en(language) { 
    return { 


      err_emailFormatError: 'Invalid email address.',
      err_emailEmpty: 'Please input email address.',
      err_passwordEmpty: 'Please input password.',
      err_oldPasswordEmpty: 'Please input old password.',
      err_newPasswordEmpty: 'Please input new password.',
      err_confirmPasswordEmpty: 'Please input confirm password.',
      err_passwordMissmatch: 'Password does not match',
      err_referralCodeEmpty: 'Please input referral code.',
      err_countryCodeEmpty: 'Please select country.',
      err_addressEmpty: 'Please input address.',
      err_labelEmpty: 'Please input label.',
      err_addressInvalid: 'Invalid address.',
      err_overWrexValue: 'Over WREX Value.',
      err_lessThenNetworkFee: 'Less then network fee.',
      err_password_too_simple: 'password is too simple',
      err_name_too_long: 'name is too long',
      err_timeout: 'request timeout',

      
      // API Error Messages
      two_fa_not_enabled: '2FA not enabled',
      login_required: 'login first',
      invalid_old_password: 'invalid old password',
      address_duplicated: 'The address is duplicated',
      address_not_specified: 'address not specified',
      already_confirmed: 'already confirmed',
      already_enabled: 'already enabled',
      duplicated_email: 'duplicated email',
      email_duplicated: 'This email has registered',
      email_not_confirmed: 'email not confirmed',
      incorrect_device: 'Incorrect device',
      insufficient_amount: 'Insufficient fund',
      invalid_address: 'Invalid address',
      invalid_affilate_code: 'Invalid affiliate code',
      invalid_email_or_password: 'Invalid email or password',
      invalid_otp: 'Invalid Code',
      invalid_yearmonth: 'Invalid Year & Month',
      invalid_reinvest_value: 'Invalid reinvest value',
      invalid_reset_link: 'invalid reset link',
      invalid_subscription_id: 'invalid subscription id',
      left_amount_less_than_1eth: 'left amount less than 1 eth',
      missing_address: 'missing address',
      missing_parameter: 'missing parameter',
      nothing_to_update: 'nothing to update',
      nothing_to_filled: 'nothing to filled',
      reinvest_not_specified: 'reinvest not specified',
      subscription_not_active: 'subscription not active',
      too_many_codes: 'too many codes',

      not_updatable: 'You are not allowed to change the settings!',   // 新增的
      amount_not_specified: 'Amount not specified', // 新增的
      invalid_wrex_withdraw_amount: 'Invalid withdrawal amount, please refer the manual withdrawal polices',
      invalid_amount: 'Invalid withdraw amount, please refer the manual withdrawl polices below',
      amount_less_than_one_eth: 'To create WREX account, you must deposit at least 1 ETH',
      not_withdrawable: 'not withdrawable',
      not_terminatable: 'not terminatable',

      invalid_user_id: 'invalid user id',
      invalid_email: 'invalue email',
      no_permission: 'no permission',
      invalid_account: 'invalid account',

      invalid_form_id: 'invalid form id',
      already_cancelled: 'already cancelled',
      already_filled: 'already filled',
      invalid_tx_hash: 'invalid tx hash',
      incorrect_amount: 'incorrect amount',
      incorrect_fee: 'incorrect fee',

      unknown_error: 'Unknown Error',

      
    } 
} 



