// @flow
import {
    RECENT_SIGNUPS,
    RECENT_SIGNUPS_SUCCESS,
    RECENT_SIGNUPS_FAILED,
    RECENT_DEPOSITS,
    RECENT_DEPOSITS_SUCCESS,
    RECENT_DEPOSITS_FAILED,
    RECENT_WITHDRAWALS,
    RECENT_WITHDRAWALS_SUCCESS,
    RECENT_WITHDRAWALS_FAILED,
} from '../../constants/actionTypes';

type RecentAction = { type: string, payload: {} | string };

export const recentSignups = (date_begin: string, date_end: string): RecentAction => ({
    type: RECENT_SIGNUPS,
    payload: { date_begin, date_end }
});

export const recentSignupsSuccess = (recentSignupsResponse: any): RecentAction => ({
    type: RECENT_SIGNUPS_SUCCESS,
    payload: recentSignupsResponse
});

export const recentSignupsFailed = (error: string): RecentAction => ({
    type: RECENT_SIGNUPS_FAILED,
    payload: error
});

export const recentDeposits = (date_begin: string, date_end: string): RecentAction => ({
    type: RECENT_DEPOSITS,
    payload: { date_begin, date_end }
});

export const recentDepositsSuccess = (recentDepositsResponse: any): RecentAction => ({
    type: RECENT_DEPOSITS_SUCCESS,
    payload: recentDepositsResponse
});

export const recentDepositsFailed = (error: string): RecentAction => ({
    type: RECENT_DEPOSITS_FAILED,
    payload: error
});

export const recentWithdrawals = (date_begin: string, date_end: string): RecentAction => ({
    type: RECENT_WITHDRAWALS,
    payload: { date_begin, date_end }
});

export const recentWithdrawalsSuccess = (recentWithdrawalsResponse: any): RecentAction => ({
    type: RECENT_WITHDRAWALS_SUCCESS,
    payload: recentWithdrawalsResponse
});

export const recentWithdrawalsFailed = (error: string): RecentAction => ({
    type: RECENT_WITHDRAWALS_FAILED,
    payload: error
});

