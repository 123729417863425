// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { API_ROOT } from '../../constants/configValue';

import { getUserToken } from '../../helpers/authUtils';

import {
    PROFIT_INFO,
    PROFIT_CALCULATE,
    PROFIT_POST,
} from '../../constants/actionTypes';


import {
    profitInfoSuccess,
    profitInfoFailed,
    profitCalculateSuccess,
    profitCalculateFailed,
    profitPostSuccess,
    profitPostFailed,
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    let myUrl = API_ROOT+url;
    //console.log("ych fetchJSON @saga.js");
    //console.log(myUrl);
    return fetch(myUrl, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


/**
 * get profit info
 * @param {*} param0 
 */
function* profitInfo({ payload: { year, month } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({
                year, month
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };

        try {
            const response = yield call(fetchJSON, '/admin/profit/info', options);
            if (response.success===false) {
                yield put(profitInfoFailed(response.message));
            } else {
                yield put(profitInfoSuccess(response));
            }
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(profitInfoFailed(response.message));
        }

    } catch (error) { }
}

/**
 * get profit calculate
 * @param {*} param0 
 */
function* profitCalculate({ payload: { year, month, total_profit } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({
                year, month, total_profit
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };

console.log(options);

        try {
            const response = yield call(fetchJSON, '/admin/profit/calculate', options);
            if (response.success===false) {
                yield put(profitCalculateFailed(response.message));
            } else {
                yield put(profitCalculateSuccess(response));
            }
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(profitCalculateFailed(response.message));
        }

    } catch (error) { }
}

/**
 * get profit post
 * @param {*} param0 
 */
function* profitPost({ payload: { year, month } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({
                year, month
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };

        try {
            const response = yield call(fetchJSON, '/admin/profit/post', options);
            if (response.success===false) {
                yield put(profitPostFailed(response.message));
            } else {
                yield put(profitPostSuccess(response));
            }
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(profitPostFailed(response.message));
        }

    } catch (error) { }
}

export function* watchProfitInfo():any {
    yield takeEvery(PROFIT_INFO, profitInfo);
}

export function* watchProfitCalculate():any {
    yield takeEvery(PROFIT_CALCULATE, profitCalculate);
}

export function* watchProfitPost():any {
    yield takeEvery(PROFIT_POST, profitPost);
}

function* profitSaga():any {
    yield all([
        fork(watchProfitInfo),
        fork(watchProfitCalculate),
        fork(watchProfitPost),
    ]);
}

export default profitSaga;
