// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { API_ROOT } from '../../constants/configValue';

import { getUserToken } from '../../helpers/authUtils';

import {
    RESET_PASSWORD,
    LOGIN_HISTORY,
} from '../../constants/actionTypes';


import {
    loginHistorySuccess,
    loginHistoryFailed,
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    let myUrl = API_ROOT+url;
    //console.log("ych fetchJSON @saga.js");
    //console.log(myUrl);
    return fetch(myUrl, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


/**
 * reset user's password
 * @param {*} param0 
 */
function* resetPassword({ payload: { email, old_password, new_password, response_receive } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({ "email": email,
                "old_password": old_password,
                "password": new_password
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };

        try {
            const response = yield call(fetchJSON, '/admin/update', options);
            response_receive(response);
            //if (response.success===false) {
                //yield put(resetPasswordFailed(response.message));
            //} else {
                //yield put(resetPasswordSuccess(response));
            //}
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";
/*
            let message;
            switch (error.status) {
                case 500: message = 'Internal Server Error'; break;
                case 401: message = 'Invalid credentials'; break;
                default: message = error;
            }
            //yield put(resetPasswordFailed(message));
*/
            response_receive(response);
        }

        //yield put(loginUserSuccess(response));

        //yield call(() => {
            //history.push("/login");
        //});
    } catch (error) { }
}

/**
 * get user's login history
 * @param {*} param0 
 */
function* loginHistory({ payload: { } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };

        try {
            const response = yield call(fetchJSON, '/admin/login.history', options);
            if (response.success===false) {
                yield put(loginHistoryFailed(response.message));
            } else {
                yield put(loginHistorySuccess(response));
            }
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(loginHistoryFailed(response.message));
        }

    } catch (error) { }
}

/*
export function* watchLoginUser():any {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser():any {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser():any {
    yield takeEvery(REGISTER_USER, register);
}
*/

export function* watchResetPassword():any {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchLoginHistory():any {
    yield takeEvery(LOGIN_HISTORY, loginHistory);
}

function* userSaga():any {
    yield all([
        //fork(watchLoginUser),
        //fork(watchLogoutUser),
        //fork(watchRegisterUser),
        fork(watchResetPassword),
        fork(watchLoginHistory),
    ]);
}

export default userSaga;
