// @flow
import {
    RESET_PASSWORD,
    LOGIN_HISTORY,
    LOGIN_HISTORY_SUCCESS,
    LOGIN_HISTORY_FAILED,
} from '../../constants/actionTypes';

type UserAction = { type: string, payload: {} | string };

export const resetPassword = (email: string, old_password: string, new_password:string, response_receive:any): UserAction => ({
    type: RESET_PASSWORD,
    payload: { email, old_password, new_password, response_receive }
});

export const loginHistory = (): UserAction => ({
    type: LOGIN_HISTORY,
    payload: {}
});

export const loginHistorySuccess = (loginHistoryResponse: any): UserAction => ({
    type: LOGIN_HISTORY_SUCCESS,
    payload: loginHistoryResponse
});

export const loginHistoryFailed = (error: string): UserAction => ({
    type: LOGIN_HISTORY_FAILED,
    payload: error
});

