// @flow
import {
    RECENT_SIGNUPS,
    RECENT_SIGNUPS_SUCCESS,
    RECENT_SIGNUPS_FAILED,
    RECENT_DEPOSITS,
    RECENT_DEPOSITS_SUCCESS,
    RECENT_DEPOSITS_FAILED,
    RECENT_WITHDRAWALS,
    RECENT_WITHDRAWALS_SUCCESS,
    RECENT_WITHDRAWALS_FAILED,

} from '../../constants/actionTypes';


const INIT_STATE = {
    loading: false
};

type RecentAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean };

const Recent = (state:State = INIT_STATE, action: RecentAction) => {
    switch (action.type) {
        case RECENT_SIGNUPS:
            return { ...state, loading: true };
        case RECENT_SIGNUPS_SUCCESS:
            return { ...state, recentSignupsResponse: action.payload, loading: false, error: null };
        case RECENT_SIGNUPS_FAILED:
            return { ...state, error: action.payload, loading: false };
        case RECENT_DEPOSITS:
            return { ...state, loading: true };
        case RECENT_DEPOSITS_SUCCESS:
            return { ...state, recentDepositsResponse: action.payload, loading: false, error: null };
        case RECENT_DEPOSITS_FAILED:
            return { ...state, error: action.payload, loading: false };
        case RECENT_WITHDRAWALS:
            return { ...state, loading: true };
        case RECENT_WITHDRAWALS_SUCCESS:
            return { ...state, recentWithdrawalsResponse: action.payload, loading: false, error: null };
        case RECENT_WITHDRAWALS_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default Recent;

