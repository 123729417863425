// @flow
import {
    USER_WALLET_SUMMARY,
    USER_WALLET_SUMMARY_SUCCESS,
    USER_WALLET_SUMMARY_FAILED,

    SYSTEM_WALLET_SUMMARY,
    SYSTEM_WALLET_SUMMARY_SUCCESS,
    SYSTEM_WALLET_SUMMARY_FAILED,

    DASHBOARD,
    DASHBOARD_SUCCESS,
    DASHBOARD_FAILED,
} from '../../constants/actionTypes';

//import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    //user: getLoggedInUser(),
    loadingUserWalletSummary: false,
    loadingSystemWalletSummary: false,
};

type DashboardAction = { type: string, payload: {} | string };
//type State = { user?: {} | null, loading?: boolean, +value?: boolean };
type State = {  loadingUserWalletSummary?: boolean, loadingSystemWalletSummary?:boolean, +value?: boolean };

const Dashboard = (state:State = INIT_STATE, action: DashboardAction) => {
    switch (action.type) {
        case USER_WALLET_SUMMARY:
            return { ...state, loadingUserWalletSummary: true };
        case USER_WALLET_SUMMARY_SUCCESS:
            return { ...state, userWalletSummaryResponse: action.payload, loadingUserWalletSummary: false, error: null };
        case USER_WALLET_SUMMARY_FAILED:
            return { ...state, error: action.payload, loadingUserWalletSummary: false };

        case SYSTEM_WALLET_SUMMARY:
            return { ...state, loadingSystemWalletSummary: true };
        case SYSTEM_WALLET_SUMMARY_SUCCESS:
            return { ...state, systemWalletSummaryResponse: action.payload, loadingSystemWalletSummary: false, error: null };
        case SYSTEM_WALLET_SUMMARY_FAILED:
            return { ...state, error: action.payload, loadingSystemWalletSummary: false };

        case DASHBOARD:
            return { ...state, loadingDashboard: true };
        case DASHBOARD_SUCCESS:
            return { ...state, dashboardResponse: action.payload, loadingDashboard: false, error: null };
        case DASHBOARD_FAILED:
            return { ...state, error: action.payload, loadingDashboard: false };
        default: return { ...state };
    }
}

export default Dashboard;

