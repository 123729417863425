// @flow
import {
    USER_ASSETS,
    USER_ASSETS_SUCCESS,
    USER_ASSETS_FAILED,
    USER_INFO,
    USER_INFO_SUCCESS,
    USER_INFO_FAILED,
    USER_EDIT,
    USER_WALLET_LOG,
    USER_WALLET_LOG_SUCCESS,
    USER_WALLET_LOG_FAILED,
} from '../../constants/actionTypes';

type ManagementAction = { type: string, payload: {} | string };

export const userAssets = (email: string, country_code: string, sorting: string): ManagementAction => ({
    type: USER_ASSETS,
    payload: { email, country_code, sorting }
});

export const userAssetsSuccess = (userAssetsResponse: any): ManagementAction => ({
    type: USER_ASSETS_SUCCESS,
    payload: userAssetsResponse
});

export const userAssetsFailed = (error: string): ManagementAction => ({
    type: USER_ASSETS_FAILED,
    payload: error
});

export const userInfo = (user_id: int, email: string, response_receive:any ): ManagementAction => ({
    type: USER_INFO,
    payload: { user_id, email, response_receive }
});

export const userInfoSuccess = (userInfoResponse: any): ManagementAction => ({
    type: USER_INFO_SUCCESS,
    payload: userInfoResponse
});

export const userInfoFailed = (error: string): ManagementAction => ({
    type: USER_INFO_FAILED,
    payload: error
});

export const userEdit = (user_id: int, field: string, value:any, response_receive:any ): ManagementAction => ({
    type: USER_EDIT,
    payload: { user_id, field, value, response_receive }
});

export const userWalletLog = (user_id: int, email: string  ): ManagementAction => ({
    type: USER_WALLET_LOG,
    payload: { user_id, email }
});

export const userWalletLogSuccess = (userWalletLogResponse: any): ManagementAction => ({
    type: USER_WALLET_LOG_SUCCESS,
    payload: userWalletLogResponse
});

export const userWalletLogFailed = (error: string): ManagementAction => ({
    type: USER_WALLET_LOG_FAILED,
    payload: error
});

