// @flow
import {
    USER_WALLET_SUMMARY,
    USER_WALLET_SUMMARY_SUCCESS,
    USER_WALLET_SUMMARY_FAILED,

    SYSTEM_WALLET_SUMMARY,
    SYSTEM_WALLET_SUMMARY_SUCCESS,
    SYSTEM_WALLET_SUMMARY_FAILED,

    DASHBOARD,
    DASHBOARD_SUCCESS,
    DASHBOARD_FAILED,
} from '../../constants/actionTypes';

type DashboardAction = { type: string, payload: {} | string };

export const userWalletSummary = (): DashboardAction => ({
    type: USER_WALLET_SUMMARY,
    payload: {}
});

export const userWalletSummarySuccess = (userWalletSummaryResponse: any): DashboardAction => ({
    type: USER_WALLET_SUMMARY_SUCCESS,
    payload: userWalletSummaryResponse
});

export const userWalletSummaryFailed = (error: string): DashboardAction => ({
    type: USER_WALLET_SUMMARY_FAILED,
    payload: error
});

export const systemWalletSummary = (): DashboardAction => ({
    type: SYSTEM_WALLET_SUMMARY,
    payload: {}
});

export const systemWalletSummarySuccess = (systemWalletSummaryResponse: any): DashboardAction => ({
    type: SYSTEM_WALLET_SUMMARY_SUCCESS,
    payload: systemWalletSummaryResponse
});

export const systemWalletSummaryFailed = (error: string): DashboardAction => ({
    type: SYSTEM_WALLET_SUMMARY_FAILED,
    payload: error
});

export const dashboard = (): DashboardAction => ({
    type: DASHBOARD,
    payload: {}
});

export const dashboardSuccess = (dashboardResponse: any): DashboardAction => ({
    type: DASHBOARD_SUCCESS,
    payload: dashboardResponse
});

export const dashboardFailed = (error: string): DashboardAction => ({
    type: DASHBOARD_FAILED,
    payload: error
});

