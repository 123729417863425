// @flow
import {
    PROFIT_INFO,
    PROFIT_INFO_SUCCESS,
    PROFIT_INFO_FAILED,
    PROFIT_CALCULATE,
    PROFIT_CALCULATE_SUCCESS,
    PROFIT_CALCULATE_FAILED,
    PROFIT_POST,
    PROFIT_POST_SUCCESS,
    PROFIT_POST_FAILED,
    PROFIT_CLEAR_ERROR,
} from '../../constants/actionTypes';


const INIT_STATE = {
    loading: false
};

type ProfitAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean };

const Profit = (state:State = INIT_STATE, action: ProfitAction) => {
    switch (action.type) {
        case PROFIT_INFO:
            return { ...state, loading: true, profitInfoResponse: undefined, profitCalculateResponse: undefined, profitPost: undefined };
        case PROFIT_INFO_SUCCESS:
            return { ...state, profitInfoResponse: action.payload, loading: false, error: null };
        case PROFIT_INFO_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PROFIT_CALCULATE:
            return { ...state, loading: true, profitInfoResponse: undefined, profitCalculateResponse: undefined, profitPost: undefined };
        case PROFIT_CALCULATE_SUCCESS:
            return { ...state, profitCalculateResponse: action.payload, loading: false, error: null };
        case PROFIT_CALCULATE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PROFIT_POST:
            return { ...state, loading: true, profitInfoResponse: undefined, profitCalculateResponse: undefined, profitPost: undefined };
        case PROFIT_POST_SUCCESS:
            return { ...state, profitPostResponse: action.payload, loading: false, error: null };
        case PROFIT_POST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PROFIT_CLEAR_ERROR:
            return { ...state, error: null, profitInfoResponse: undefined };
        default: return { ...state };
    }
}

export default Profit;

