// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { API_ROOT } from '../../constants/configValue';

import { getUserToken } from '../../helpers/authUtils';

import {
    USER_ASSETS,
    USER_INFO,
    USER_EDIT,
    USER_WALLET_LOG,
} from '../../constants/actionTypes';


import {
    userAssetsSuccess,
    userAssetsFailed,
    userInfoSuccess,
    userInfoFailed,
    userWalletLogSuccess,
    userWalletLogFailed,
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    let myUrl = API_ROOT+url;
    //console.log("ych fetchJSON @saga.js");
    //console.log(myUrl);
    return fetch(myUrl, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}

function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

/**
 * get user assets
 * @param {*} param0 
 */
function* userAssets({ payload: { email, country_code, sorting} }) {
    try {

        let token = getUserToken();
        const limit = 100;
        if (sorting==="") {
            sorting="referral_count";
        }

        let myBody;
        email = email.trim();
        //console.log(email);
	if (validateEmail(email)) {
	    //console.log("validate ok");
            myBody = JSON.stringify({
                email, country_code, limit, sorting

            });
	} else {
	    //console.log("validate fail");
            let user_id = parseInt(email);
            myBody = JSON.stringify({
                user_id, country_code, limit, sorting

            });
	}
        const options = {
/*
            body: JSON.stringify({
                email, country_code, limit, sorting

            }),
*/
            body: myBody,
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };
//console.log(options);

        try {
            const response = yield call(fetchJSON, '/admin/user.assets', options);
            if (response.success===false) {
                yield put(userAssetsFailed(response.message));
            } else {
                yield put(userAssetsSuccess(response));
            }
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(userAssetsFailed(response.message));
        }

    } catch (error) { }
}

/**
 * get user info
 * @param {*} param0 
 */
function* userInfo({ payload: { user_id, email, response_receive } }) {
    try {

        let token = getUserToken();
        let options = {}
        if (user_id!==-1) {
            options = {
                body: JSON.stringify({
                    user_id

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        } else {
            options = {
                body: JSON.stringify({
                    email

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        }
//console.log(options);

        try {
            const response = yield call(fetchJSON, '/admin/user.info', options);
            if (response.success===false) {
                yield put(userInfoFailed(response.message));
            } else {
                yield put(userInfoSuccess(response));
            }
            if (response_receive!==null) {
                response_receive(response);
            }

        } catch (error) {
//console.log(error);
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(userInfoFailed(response.message));
            if (response_receive!==null) {
                response_receive(response);
            }
        }

    } catch (error) { }
}

/**
 * edit user 
 * @param {*} param0 
 */
function* userEdit({ payload: { user_id, field, value, response_receive } }) {
    try {

        let token = getUserToken();
//console.log(field);
        let options;
        if (field==="account_type") {
            options = {
                body: JSON.stringify({
                    "user_id": user_id,
		    "account_type": value

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        } else if (field==="level") {
            options = {
                body: JSON.stringify({
                    "user_id": user_id,
		    "level": value

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        } else if (field==="limit_max_deposit") {
            options = {
                body: JSON.stringify({
                    "user_id": user_id,
		    "limit_max_deposit": value

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        } else if (field==="reset_password") {
            options = {
                body: JSON.stringify({
                    "user_id": user_id,
		    "reset_password": value

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        } else if (field==="status") {
            options = {
                body: JSON.stringify({
                    "user_id": user_id,
		    "status": value

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        }
//console.log(options);

        try {
            const response = yield call(fetchJSON, '/admin/user.edit', options);
            if (response.success===false) {
                yield put(userInfoFailed(response.message));
            } else {
                yield put(userInfoSuccess(response));
            }
            if (response_receive!==null) {
                response_receive(response);
            }

        } catch (error) {
//console.log(error);
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(userInfoFailed(response.message));
            if (response_receive!==null) {
                response_receive(response);
            }
        }

    } catch (error) { }
}

/**
 * get user wallet log
 * @param {*} param0 
 */
function* userWalletLog({ payload: { user_id, email } }) {
    try {

        let token = getUserToken();
        let options = {}
        if (user_id!==-1) {
            options = {
                body: JSON.stringify({
                    user_id

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        } else {
            options = {
                body: JSON.stringify({
                    email

                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                           'Authorization': 'Bearer '+token,
                    }
            };
        }
//console.log(options);

        try {
            const response = yield call(fetchJSON, '/admin/user.wallet.log', options);
            if (response.success===false) {
                yield put(userWalletLogFailed(response.message));
            } else {
                yield put(userWalletLogSuccess(response));
            }

        } catch (error) {
//console.log(error);
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(userWalletLogFailed(response.message));
        }

    } catch (error) { }
}


export function* watchUserAssets():any {
    yield takeEvery(USER_ASSETS, userAssets);
}

export function* watchUserInfo():any {
    yield takeEvery(USER_INFO, userInfo);
}

export function* watchUserEdit():any {
    yield takeEvery(USER_EDIT, userEdit);
}

export function* watchUserWalletLog():any {
    yield takeEvery(USER_WALLET_LOG, userWalletLog);
}

function* managementSaga():any {
    yield all([
        fork(watchUserAssets),
        fork(watchUserInfo),
        fork(watchUserEdit),
        fork(watchUserWalletLog),
    ]);
}

export default managementSaga;
