// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { API_ROOT } from '../../constants/configValue';

import { getUserToken } from '../../helpers/authUtils';

import {
    MYETH_WITHDRAWALS,
} from '../../constants/actionTypes';


import {
    myethWithdrawalsSuccess,
    myethWithdrawalsFailed,
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    let myUrl = API_ROOT+url;
    //console.log("ych fetchJSON @saga.js");
    //console.log(myUrl);
    return fetch(myUrl, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


/**
 * get myeth withdrawals list
 * @param {*} param0 
 */
function* myethWithdrawals({ payload: { } }) {
    try {

        let token = getUserToken();
        const options = {
            body: JSON.stringify({
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };

        try {
            const response = yield call(fetchJSON, '/admin/myeth.withdrawals', options);
            if (response.success===false) {
                yield put(myethWithdrawalsFailed(response.message));
            } else {
                yield put(myethWithdrawalsSuccess(response));
            }
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(myethWithdrawalsFailed(response.message));
        }

    } catch (error) { }
}

export function* watchMyethWithdrawals():any {
    yield takeEvery(MYETH_WITHDRAWALS, myethWithdrawals);
}

function* requestSaga():any {
    yield all([
        fork(watchMyethWithdrawals),
    ]);
}

export default requestSaga;
