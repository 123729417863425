// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { API_ROOT } from '../../constants/configValue';

import { getUserToken } from '../../helpers/authUtils';

import {
    PAYABLE_LIST,
    PAYABLE_COLLECT,
    RECEIVABLE_LIST,
    RECEIVABLE_COLLECT,
} from '../../constants/actionTypes';


import {
    payableListSuccess,
    payableListFailed,
    receivableListSuccess,
    receivableListFailed,
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    let myUrl = API_ROOT+url;
    //console.log("ych fetchJSON @saga.js");
    //console.log(myUrl);
    return fetch(myUrl, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}

/**
 * get payable list
 * @param {*} param0 
 */
function* payableList({ payload: { account } }) {
    try {

        let token = getUserToken();
        const limit = 100;
        const options = {
            body: JSON.stringify({
                account
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };
//console.log(options);

        try {
            const response = yield call(fetchJSON, '/admin/payable.list', options);
            if (response.success===false) {
                yield put(payableListFailed(response.message));
            } else {
                yield put(payableListSuccess(response));
            }
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(payableListFailed(response.message));
        }

    } catch (error) { }
}

/**
 * payable collect
 * @param {*} param0
 */
function* payableCollect({ payload: { transactions, response_receive } }) {
    try {
        
        let token = getUserToken();
        const options = {
            body: JSON.stringify({ "transactions": transactions
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };
        
        try {
            const response = yield call(fetchJSON, '/admin/payable.collect', options);
            response_receive(response);
        } catch (error) {
            console.log(error);
            let response={}; 
            response.success = false;
            response.message = "unknown_error";
            response_receive(response);
        }
    
    } catch (error) {
        console.log(error);
    }
}


/**
 * get receivable list
 * @param {*} param0 
 */
function* receivableList({ payload: { account } }) {
    try {

        let token = getUserToken();
        const limit = 100;
        const options = {
            body: JSON.stringify({
                account
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };
//console.log(options);

        try {
            const response = yield call(fetchJSON, '/admin/receivable.list', options);
            if (response.success===false) {
                yield put(receivableListFailed(response.message));
            } else {
                yield put(receivableListSuccess(response));
            }
        } catch (error) {
            let response={};
            response.success = false;
            response.message = "unknown_error";

            switch (error.status) {
                case 500: response.message = 'Internal Server Error'; break;
                case 401: response.message = 'Invalid credentials'; break;
            }

            yield put(receivableListFailed(response.message));
        }

    } catch (error) { }
}

/**
 * receivable collect
 * @param {*} param0
 */
function* receivableCollect({ payload: { transactions, response_receive } }) {
    try {
        
        let token = getUserToken();
        const options = {
            body: JSON.stringify({ "transactions": transactions
            }),
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': 'Bearer '+token,
                }
        };
        
        try {
            const response = yield call(fetchJSON, '/admin/receivable.collect', options);
            response_receive(response);
        } catch (error) {
            let response={}; 
            response.success = false;
            response.message = "unknown_error";
            response_receive(response);
        }
    
    } catch (error) { }
}


export function* watchPayableList():any {
    yield takeEvery(PAYABLE_LIST, payableList);
}

export function* watchPayableCollect():any {
    yield takeEvery(PAYABLE_COLLECT, payableCollect);
}

export function* watchReceivableList():any {
    yield takeEvery(RECEIVABLE_LIST, receivableList);
}

export function* watchReceivableCollect():any {
    yield takeEvery(RECEIVABLE_COLLECT, receivableCollect);
}

function* transferSaga():any {
    yield all([
        fork(watchPayableList),
        fork(watchPayableCollect),
        fork(watchReceivableList),
        fork(watchReceivableCollect),
    ]);
}

export default transferSaga;
