// @flow
import {
    TRANSFER_FORM_LIST,
    TRANSFER_FORM_LIST_SUCCESS,
    TRANSFER_FORM_LIST_FAILED,
    TRANSFER_FORM_CANCEL,
    TRANSFER_FORM_CHECK,
    TRANSFER_FORM_CHECK_SUCCESS,
    TRANSFER_FORM_CHECK_FAILED,
    TRANSFER_FORM_SETTLE,
    TRANSFER_FORM_SETTLE_RESPONSE,
} from '../../constants/actionTypes';

type PostAction = { type: string, payload: {} | string };

export const transferFormList = (only_unfilled: boolean): PostAction => ({
    type: TRANSFER_FORM_LIST,
    payload: { only_unfilled }
});

export const transferFormListSuccess = (transferFormListResponse: any): PostAction => ({
    type: TRANSFER_FORM_LIST_SUCCESS,
    payload: transferFormListResponse
});

export const transferFormListFailed = (error: string): PostAction => ({
    type: TRANSFER_FORM_LIST_FAILED,
    payload: error
});

export const transferFormCancel = (form_id: int, response_receive:any): PostAction => ({
    type: TRANSFER_FORM_CANCEL,
    payload: { form_id, response_receive }
});

export const transferFormCheck = (form_id: int): PostAction => ({
    type: TRANSFER_FORM_CHECK,
    payload: { form_id }
});

export const transferFormCheckSuccess = (transferFormCheckResponse: any): PostAction => ({
    type: TRANSFER_FORM_CHECK_SUCCESS,
    payload: transferFormCheckResponse
});

export const transferFormCheckFailed = (error: string): PostAction => ({
    type: TRANSFER_FORM_CHECK_FAILED,
    payload: error
});

export const transferFormSettle = (form_id: int, tx_hash: string, amount: string, fee: string, response_receive:any): PostAction => ({
    type: TRANSFER_FORM_SETTLE,
    payload: { form_id, tx_hash, amount, fee, response_receive }
});

export const transferFormSettleResponse = (): PostAction => ({
    type: TRANSFER_FORM_SETTLE_RESPONSE,
    payload: {}
});

