// @flow
import {
    MYETH_WITHDRAWALS,
    MYETH_WITHDRAWALS_SUCCESS,
    MYETH_WITHDRAWALS_FAILED,

} from '../../constants/actionTypes';


const INIT_STATE = {
    loading: false
};

type RequestAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean };

const Request = (state:State = INIT_STATE, action: RequestAction) => {
    switch (action.type) {
        case MYETH_WITHDRAWALS:
            return { ...state, loading: true };
        case MYETH_WITHDRAWALS_SUCCESS:
            return { ...state, myethWithdrawalsResponse: action.payload, loading: false, error: null };
        case MYETH_WITHDRAWALS_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default Request;

